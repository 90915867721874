<template lang="">
  <div class="row">
    <div class="col-md-6">
      <div class="mb-2">
        <label class="form-label">แท็ก</label>
        <base-input name="tag" placeholder="แท็ก" v-model="item.v" :rules="{required: true}"></base-input>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-2">
        <label class="form-label">หมวด</label>
        <base-input name="key" placeholder="หมวด" v-model="item.k" :rules="{required: true}"></base-input>
      </div>
    </div>
    <div class="col-sm-12">
      <hr class="my-4">
    </div>
  </div>
</template>
<script>
export default {
  name: 'product_tag-view-form-product_tag',
  props: [
    'item',
    'action'
  ]
}
</script>
